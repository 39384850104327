export default {
  issuedFrom: {
    name: "issuedDate",
    type: "gte",
    value: ""
  },
  issuedTo: {
    name: "issuedDate",
    type: "lte",
    value: ""
  },
  contractType: {
    name: "type",
    type: "where",
    value: ""
  },
  id: {
    name: "id",
    type: "where",
    value: ""
  },
  contractNumber: {
    name: "number",
    type: "where",
    value: ""
  },
  counterpart_id: {
    name: "counterpartId",
    type: "where",
    value: ""
  },
  status: {
    name: "status",
    type: "where",
    value: ""
  },
  cPartId: {
    value: "",
    virtualValue: null
  },
  financialModel: {
    name: "financialModel",
    type: "whereIn",
    value: []
  },
  withDeleted: {
    name: "deleted",
    type: "with",
    value: ""
  },
  destinationCode: {
    name: "destinationCode",
    type: "where",
    value: ""
  },
  alias: {
    name: "alias",
    type: "where",
    value: ""
  }
};

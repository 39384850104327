<template>
  <b-card :header="caption">
    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <b-button
          v-if="$auth.can('create contract')"
          variant="success"
          size="sm"
          @click="newItemModal = true"
          class="mb-2"
        >
          <i class="fa fa-dot-circle-o"></i> Додати
        </b-button>
      </b-col>

      <b-col cols="auto" class="p-3">
        <b-button variant="primary" size="sm" @click="exportData" class="mb-2">
          <i class="fa fa-dot-circle-o"></i> Сформувати перелік
        </b-button>
      </b-col>
    </b-row>

    <filter-form />

    <b-table
      :dark="false"
      :fields="fields"
      :hover="false"
      :striped="true"
      :bordered="true"
      :fixed="true"
      responsive="sm"
      :items="applyShortFilter(dataList)"
      :current-page="page"
    >
      <template v-slot:cell(counterpart)="data">
        <b-link @click="followToCounterpart(data.item.counterpart.id)">
          {{ data.item.counterpart.name }}
        </b-link>
      </template>

      <template v-slot:cell(edrpou)="data">
        {{
          data.item.counterpart.usreou_code
            ? data.item.counterpart.usreou_code
            : data.item.counterpart.itn_code
            ? data.item.counterpart.itn_code
            : data.item.counterpart.non_resident_code
        }}
      </template>

      <template v-slot:cell(issued)="data">
        {{ data.item.issued | date }}
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          v-if="!data.item.deleted"
          variant="ghost-info link"
          size="sm"
          @click="followToCurrentItem(data.item.id)"
        >
          <i class="fa fa-edit"></i>
        </b-button>

        <b-button
          v-if="$auth.can('create contract') && !data.item.deleted"
          variant="ghost-danger"
          size="sm"
          @click="deleteItem(data.item.id)"
        >
          <i class="fa fa-close"></i>
        </b-button>

        <b-button
          v-if="$auth.can('create contract') && data.item.deleted"
          variant="ghost-danger"
          size="sm"
          @click="restoreItem(data.item.id)"
        >
          <i class="fa fa-undo"></i>
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col cols="auto" class="mr-auto p-3">
        <nav>
          <b-pagination
            :total-rows="total"
            :per-page="count"
            v-model="page"
            prev-text="Попередня"
            next-text="Наступна"
            hide-goto-end-buttons
          />
        </nav>
      </b-col>

      <b-col cols="auto" class="p-3">
        <b-form-radio-group
          buttons
          v-model="count"
          :options="options"
          button-variant="outline-primary"
        />
      </b-col>
    </b-row>

    <b-modal
      id="contractForm"
      title="Новий договір"
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="newItemModal"
      @ok="newItemModal = false"
    >
      <contract-form />
    </b-modal>

    <b-modal id="modalRemove" @ok="okRemove">
      <p class="my-4">Впевнені ?</p>
    </b-modal>

    <b-modal id="modalRestore" @ok="okRestore">
      <p class="my-4">Відновити ?</p>
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import contractForm from "./forms/ContractForm";
import debounce from "debounce";
import FilterForm from "./forms/FilterForm";
import { mapFields } from "vuex-map-fields";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can("view contract")) next();
      else next({ name: "Page404" });
    });
  },
  name: "contract-list",
  components: { contractForm, FilterForm },
  data() {
    return {
      options: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "200", value: 200 }
      ],
      fields: [
        { key: "id", label: "ID" },
        { key: "counterpart", label: "Контрагент" },
        { key: "edrpou", label: "ЄДРПОУ/РНОКПП" },
        { key: "contractType", label: "Договір" },
        { key: "number", label: "Номер" },
        { key: "financialModel", label: "Профіль СПФМ" },
        { key: "issued", label: "Підписаний/Введений в дію" },
        { key: "status", label: "Статус" },
        { key: "actions", label: "Дії" }
      ],
      itemEndpoint: "/entity/contract",
      counterpartEndpoint: "/entity/counterpart",
      caption: "Договори",
      newItemModal: false,
      removeId: null,
      restoreId: null
    };
  },
  computed: {
    ...mapGetters({
      dataList: "contracts/getAllContracts",
      financialModels: "financialModels/getAllFinancialModels",
      types: "dictionary/allContractTypes",
      statuses: "dictionary/allContractStatuses"
    }),
    ...mapFields("contracts", {
      count: "count",
      total: "total",
      page: "page"
    })
  },
  watch: {
    page: function() {
      this.debouncedPaginator();
    },
    count: function() {
      this.debouncedPaginator();
    }
  },
  created() {
    this.debouncedPaginator = debounce(this.paginator, 500);
  },
  methods: {
    exportData: function() {
      this.$store.dispatch("contracts/exportData");
    },
    paginator: function() {
      this.$store.dispatch("contracts/setContracts");
    },
    applyShortFilter: data => {
      return data.map(item => {
        return {
          id: item.id,
          number: item.number,
          issued: item.issued_date,
          status: item.state.name,
          contractType: item.type.name,
          financialModel: item.financial_model.name,
          counterpart: Object.assign({}, item.counterpart),
          deleted: item.deleted_at,
          _rowVariant: item.deleted_at ? "danger" : null
        };
      });
    },
    followToCounterpart(itemId) {
      this.$router.push(this.counterpartEndpoint + "/" + itemId);
    },
    followToCurrentItem(itemId) {
      this.$router.push(this.itemEndpoint + "/" + itemId);
    },
    okRemove() {
      this.$store.dispatch("contracts/deleteItem", this.removeId);
    },
    deleteItem(itemId) {
      this.removeId = itemId;
      this.$root.$emit("bv::show::modal", "modalRemove");
    },
    okRestore() {
      this.$store.dispatch("contracts/restoreItem", this.restoreId);
    },
    restoreItem(itemId) {
      this.restoreId = itemId;
      this.$root.$emit("bv::show::modal", "modalRestore");
    }
  }
};
</script>
